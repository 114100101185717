import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Grid, ImageList, ImageListItem, ImageListItemBar, Link } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'i18n';
import Image from 'components/common/Image';
import VideoImage from 'components/event/VideoImage';
import NextImage from 'next/legacy/image';

const cellHeight = 112;
const gridListWidth = 360;
const maxTiles = 6;
const cellPadding = 8;
const spacing = cellPadding * 2;

const cols = 2;
const rows = 2;
const colsOffset = 4;
const rowsOffset = 2;

// TODO jss-to-tss-react codemod: Unable to handle style definition reliably. ArrowFunctionExpression in CSS prop.
const useStyles = makeStyles()((theme, props) => ({
  mainContainer: {
    maxWidth: '752px',
  },
  container: {
    flexWrap: 'nowrap',
    overflowX: 'auto',
    ...theme.mixins.scrollBarOverflowYAuto,
  },
  gridTileBar: {
    height: '100% !important',
    textAlign: 'center',
  },
  image: {
    width: 172,
    height: (cellHeight + cellPadding) * rows,
    position: 'relative',
  },
  gridList: {
    margin: 0,
    width: props.smallTiles.length === 2 ? gridListWidth / 2 : gridListWidth,
  },
}));

const useTileStyles = makeStyles()({
  tile: {
    borderRadius: 8,
    overflow: 'hidden',
    cursor: 'pointer',
  },
});

function OrgProfileGallery({ files, onImageClick }) {
  const i = maxTiles - Math.min(maxTiles, files.length) + 2;
  const bigTiles = files.slice(0, i);
  const smallTiles = files.slice(i, maxTiles);

  const stylesProps = { smallTiles };
  const { classes, cx } = useStyles(stylesProps, {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    props: stylesProps,
  });
  const { classes: tileClasses } = useTileStyles();
  const [t] = useTranslation('public');

  const hasMore = files.length > maxTiles;
  const currentCellHeight = smallTiles.length > 1 ? cellHeight : cellHeight + cellPadding;
  return (
    <Grid className={classes.container} container spacing={2}>
      {bigTiles.map((tile) => {
        const handleImageClick = () => {
          onImageClick(tile);
        };

        return (
          <Grid item key={tile.id}>
            {tile.link ? (
              <VideoImage
                file={tile}
                variant="rectangle"
                onClick={handleImageClick}
                className={cx(classes.image, tileClasses.tile)}
                component={Image}
              />
            ) : (
              <Image
                variant="rectangle"
                onClick={handleImageClick}
                className={cx(classes.image, tileClasses.tile)}
                src={tile.original_url || tile.image.original_url}
                alt={tile.internal_title || tile.image.filename}
              />
            )}
          </Grid>
        );
      })}
      <Grid item>
        <ImageList className={classes.gridList} gap={spacing} rowHeight={currentCellHeight} cols={cols}>
          {smallTiles.map((tile, index) => {
            const onClick = () => {
              onImageClick(tile);
            };

            const colSize = smallTiles.length + index < colsOffset ? 2 : 1;
            const rowSize = smallTiles.length + index < rowsOffset ? 2 : 1;
            const isLastIndex = smallTiles.length - 1 === index;

            return (
              <ImageListItem key={tile.id} className={tileClasses.tile} cols={colSize} rows={rowSize} onClick={onClick}>
                {tile.link ? (
                  <VideoImage file={tile} />
                ) : (
                  <NextImage
                    style={{ 'object-fit': 'cover' }}
                    src={tile.image.original_url}
                    alt={tile.image.internal_title}
                    layout="fill"
                  />
                )}
                {hasMore && isLastIndex && (
                  <ImageListItemBar
                    className={classes.gridTileBar}
                    title={
                      <Link variant="button" component="button" color="inherit">
                        {t('common:button.viewAll')}
                      </Link>
                    }
                  />
                )}
              </ImageListItem>
            );
          })}
        </ImageList>
      </Grid>
    </Grid>
  );
}

OrgProfileGallery.propTypes = {
  files: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
    }),
  ).isRequired,
  onImageClick: PropTypes.func.isRequired,
};

export default memo(OrgProfileGallery);
