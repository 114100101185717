import React, { memo, useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Container, Grid, Link, Typography, useMediaQuery } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'i18n';
import { routes } from 'utils/routing';
import PageSwitcher from 'components/common/PageSwitcher';
import ResponsiveGrid from 'components/common/ResponsiveGrid';
import Button from 'components/common/Button';
import Logo from 'components/common/Logo';
import Image from 'components/common/Image';

const itemsPerPage = 8;

interface IProps {
  organizations: any[];
  className?: string;
  backgroundImage?: string;
}

interface StyleProps {
  backgroundImage?: string;
}

const useStyles = makeStyles<StyleProps>()((theme, props: StyleProps) => ({
  wrapper: {
    position: 'relative',
    zIndex: 1,
    [theme.breakpoints.down('md')]: {
      height: 'auto',
    },
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: -1,
      background:
        props.backgroundImage &&
        `linear-gradient(180deg, ${theme.palette.background.default} 0%, rgba(245, 246, 250, 0) 100%),
        no-repeat center/cover url(${props.backgroundImage})`,
      opacity: 0.08,
    },
  },
  textContainer: {
    height: '100%',
  },
  logosContainer: {
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(16),
    },
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
      marginTop: theme.spacing(3),
    },
  },
  pageSwitcher: {
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(3),
    },
  },
  hasMoreButtonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(4),
  },
  orgLogo: {
    backgroundColor: 'white',
    padding: theme.spacing(2),
  },
}));

const AffiliatedOrganizations: React.FC<React.PropsWithChildren<IProps>> = (props) => {
  const { organizations, className, backgroundImage } = props;
  const { classes } = useStyles({ backgroundImage });
  const [t] = useTranslation('public');

  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('md'));

  const [page, setPage] = useState(1);
  const [expanded, setExpanded] = useState(false);

  const totalPages = Math.ceil(organizations.length / itemsPerPage);
  const hasOnePage = totalPages === 1;
  const hasMore = organizations.length - itemsPerPage;
  const isForwardDisabled = page >= totalPages;
  const isBackwardDisabled = page <= 1;
  const displayedOrganizations =
    expanded && isMobile ? organizations : organizations.slice((page - 1) * itemsPerPage, page * itemsPerPage);

  const handlePageForward = () => {
    setPage(page + 1);
  };

  const handlePageBack = () => {
    setPage(page - 1);
  };

  const handleExpanded = () => {
    setExpanded((expanded) => !expanded);
  };

  return (
    <div className={classes.wrapper}>
      <Container maxWidth="lg">
        <Grid container className={className}>
          <Grid item xs={12} md={3}>
            <Grid container className={classes.textContainer} justifyContent="space-between">
              <Grid item xs={12}>
                <Typography variant="h4">{t<string>('organization.affiliatedOrganizations')}</Typography>
              </Grid>

              {!isMobile && !hasOnePage && (
                <Grid item container className={classes.pageSwitcher} alignItems="flex-end">
                  <Grid item>
                    <PageSwitcher
                      onPageForward={handlePageForward}
                      onPageBack={handlePageBack}
                      currentPage={page}
                      totalPages={totalPages}
                      isForwardDisabled={isForwardDisabled}
                      isBackwardDisabled={isBackwardDisabled}
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>

          <ResponsiveGrid item xs container xsSpacing={2} lgSpacing={4} className={classes.logosContainer}>
            {displayedOrganizations.map((org) => (
              <ResponsiveGrid item key={org.id}>
                <Link color="inherit" href={routes.organizationProfile(org.id).link}>
                  <Logo className={classes.orgLogo} xs={136} sm={160} md={160}>
                    <Image src={org.logo} alt={org.title} />
                  </Logo>
                </Link>
              </ResponsiveGrid>
            ))}
          </ResponsiveGrid>

          {isMobile && hasMore > 0 && !expanded && (
            <div className={classes.hasMoreButtonContainer}>
              <Button
                size="small"
                color="primary"
                onClick={handleExpanded}
                endIcon={<ExpandMoreIcon color="primary" />}
              >
                {hasMore} {t<string>('common:button.more')}
              </Button>
            </div>
          )}
        </Grid>
      </Container>
    </div>
  );
};

export default memo(AffiliatedOrganizations);
