import React, { memo } from 'react';
import PropTypes from 'prop-types';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Grid, Typography, IconButton } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  pageCounter: {
    fontWeight: 500,
  },
}));

function PageSwitcher({ currentPage, totalPages, onPageBack, onPageForward, isForwardDisabled, isBackwardDisabled }) {
  const { classes } = useStyles();
  return (
    <Grid container direction="row" alignItems="center" spacing={2}>
      <Grid item>
        <IconButton onClick={onPageBack} disabled={isBackwardDisabled} size="large">
          <ArrowBackIosIcon fontSize="small" />
        </IconButton>
      </Grid>
      <Grid item>
        <Typography variant="body2" className={classes.pageCounter}>
          {currentPage} / {totalPages}
        </Typography>
      </Grid>
      <Grid item>
        <IconButton onClick={onPageForward} disabled={isForwardDisabled} size="large">
          <ArrowForwardIosIcon fontSize="small" />
        </IconButton>
      </Grid>
    </Grid>
  );
}

PageSwitcher.propTypes = {
  onPageBack: PropTypes.func.isRequired,
  onPageForward: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  isBackwardDisabled: PropTypes.bool,
  isForwardDisabled: PropTypes.bool,
};

export default memo(PageSwitcher);
