import React, { useEffect } from 'react';
import { Container, Divider, Grid, NoSsr, Theme, useMediaQuery } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useModal } from 'hooks';

import Image from 'components/common/Image';
import Logo from 'components/common/Logo';
import OrgProfileImage from 'components/organization/OrgProfileImage';
import OrgSidebar from 'components/organization/OrgSidebar';
import OrgProfileDescription from 'components/organization/OrgProfileDescription';
import AffiliatedOrganizations from 'components/organization/AffiliatedOrganizations';
import ImageGalleryDialog from 'components/event/ImageGalleryDialog';
import UpcomingAndPastEvents from 'components/organization/UpcomingAndPastEvents';
import { trackPage } from './ScrenTracker';

const useStyles = makeStyles()((theme) => ({
  wrapper: {
    backgroundColor: theme.palette.background.default,
    overflowX: 'hidden',
  },
  mainContainer: {
    padding: theme.spacing(12, 0),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(5, 0),
    },
  },
  topContainer: {
    position: 'relative',
  },
  sidebarMargin: {
    marginTop: theme.spacing(2),
    marginRight: 100,
    [theme.breakpoints.down('lg')]: {
      marginRight: 40,
    },
    [theme.breakpoints.down('md')]: {
      margin: theme.spacing(5, 0),
    },
  },
  eventsContainer: {
    backgroundColor: 'white',
    overflowX: 'hidden',
  },
  mobileImageHeader: {
    [theme.breakpoints.down('md')]: {
      height: '200px',
    },
  },
  orgLogoContainer: {
    position: 'absolute',
    top: 0,
    transform: 'translateY(-50%)',
  },
  orgLogo: {
    backgroundColor: 'white',
    padding: theme.spacing(2),
  },
  descriptionContainer: {
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(5),
    },
  },
}));

interface OrganizationScreenProps {
  organization: any;
  upcomingEvents: any[];
  pastEvents: any[];
  isPastEventFetching: boolean;
  isUpcomingEventFetching: boolean;
  onToggleFavoriteEvent?: any;
}

const OrganizationScreen: React.FC<OrganizationScreenProps> = ({
  organization,
  upcomingEvents,
  pastEvents,
  isPastEventFetching,
  isUpcomingEventFetching,
  onToggleFavoriteEvent,
}) => {
  const {
    id,
    images,
    videos,
    title,
    tagline,
    description,
    address,
    parent,
    family_tree: familyTree,
    site_url,
    site_url_preview,
    email,
    facebook,
    twitter,
    instagram,
    settings,
  } = organization;
  const { classes, cx } = useStyles();

  const [showImageGallery] = useModal(ImageGalleryDialog);
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const galleryFiles = images
    .filter(({ logo, main }: { logo: any; main: any }) => !logo && !main)
    .concat(videos)
    .sort((a: any, b: any) => a.order - b.order);
  const mainImage = images.find((image: any) => image.main === true);
  const mainLogo = images.find((image: any) => image.logo === true);

  useEffect(() => {
    trackPage('Organisation Page');
  }, []);

  const handleImageClick = (image: any) => {
    const index = galleryFiles.indexOf(image);
    showImageGallery({
      files: galleryFiles,
      initialIndex: index,
    });
  };

  return (
    <NoSsr>
      <Grid container className={classes.wrapper}>
        <Grid item xs={12}>
          {mainImage && mainImage.image.original_url && (
            <OrgProfileImage
              src={mainImage.image.original_url}
              alt={mainImage.image.filename}
              className={classes.mobileImageHeader}
            />
          )}
        </Grid>

        <Grid item xs={12}>
          <Container maxWidth="lg">
            <Grid
              container
              wrap={isMobile ? 'wrap' : 'nowrap'}
              className={cx(classes.mainContainer, classes.topContainer)}
            >
              <Grid item className={classes.orgLogoContainer}>
                <Logo className={classes.orgLogo} sm={96} xs={96} md={160}>
                  {mainLogo && mainLogo.image.original_url && (
                    <Image src={mainLogo && mainLogo.image.original_url} alt={mainLogo && mainLogo.image.filename} />
                  )}
                </Logo>
              </Grid>
              <Grid item xs={12} md="auto" className={classes.sidebarMargin}>
                <OrgSidebar
                  title={title}
                  address={address}
                  parent={parent}
                  siteUrl={site_url}
                  siteUrlPreview={site_url_preview}
                  email={email}
                  twitter={twitter}
                  facebook={facebook}
                  instagram={instagram}
                  settings={settings}
                />
              </Grid>

              {isMobile && (
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              )}

              <Grid item xs={12} md className={classes.descriptionContainer}>
                <OrgProfileDescription
                  files={galleryFiles}
                  tagline={tagline}
                  description={description}
                  settings={settings}
                  onImageClick={handleImageClick}
                />
              </Grid>
            </Grid>
          </Container>

          <div className={classes.eventsContainer}>
            <Container maxWidth="lg">
              <Grid container className={classes.mainContainer}>
                <Grid item xs={12}>
                  <UpcomingAndPastEvents
                    organizationId={id}
                    upcomingEvents={upcomingEvents}
                    pastEvents={pastEvents}
                    isPastEventFetching={isPastEventFetching}
                    isUpcomingEventFetching={isUpcomingEventFetching}
                    onToggleFavoriteEvent={onToggleFavoriteEvent}
                  />
                </Grid>
              </Grid>
            </Container>
          </div>

          {familyTree.length > 0 && (
            <AffiliatedOrganizations
              organizations={familyTree}
              backgroundImage={mainImage?.image?.original_url}
              className={classes.mainContainer}
            />
          )}
        </Grid>
      </Grid>
    </NoSsr>
  );
};

export default OrganizationScreen;
