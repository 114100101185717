import React from 'react';
import Head from 'next/head';
import { useTranslation } from 'i18n';

export type TabTitleProps = Record<string, any> & {
  i18nKey?: string;
  ns?: string | string[];
  tOptions?: {};
  signLabel?: 'general' | 'admin';
  directTitle?: string;
};

const TabTitle: React.FC<React.PropsWithChildren<TabTitleProps>> = ({
  i18nKey,
  ns,
  tOptions,
  signLabel,
  directTitle,
  ...rest
}) => {
  const [t] = useTranslation(ns);

  const projectStamp = signLabel ? ` | ${t<string>('common:general.projectStamp.logo', { context: signLabel })}` : '';
  const tabTitle = (directTitle || t(i18nKey, { ...tOptions, ...rest })) + projectStamp;

  return (
    <Head>
      <title>{tabTitle}</title>
    </Head>
  );
};

export default TabTitle;
