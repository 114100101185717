import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Button, Grid, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import OrgProfileGallery from './OrgProfileGallery';

const useStyles = makeStyles()((theme) => ({
  descriptionTitle: {
    fontSize: '42px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '24px',
    },
  },
}));

function OrgProfileDescription({ files, tagline, description, settings, onImageClick }) {
  const { classes } = useStyles();
  return (
    <Grid container direction="column" spacing={4}>
      {tagline && (
        <Grid item xs={12}>
          <Typography className={classes.descriptionTitle} variant="h3">
            {tagline}
          </Typography>
        </Grid>
      )}
      <Grid item xs={12}>
        <Typography>{description}</Typography>
      </Grid>
      {settings?.can_have_linked_page && settings?.linked_page_button_text && settings?.linked_page_button_url && (
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            href={settings?.linked_page_button_url}
            underline="unset"
            target="_blank"
          >
            {settings?.linked_page_button_text}
          </Button>
        </Grid>
      )}
      <Grid item xs={12} style={{ width: '100%' }}>
        {files?.length > 0 && <OrgProfileGallery files={files} onImageClick={onImageClick} />}
      </Grid>
    </Grid>
  );
}

/* eslint-disable react/forbid-prop-types */
OrgProfileDescription.propTypes = {
  files: PropTypes.arrayOf(PropTypes.any).isRequired,
  onImageClick: PropTypes.func.isRequired,
  tagline: PropTypes.string,
  description: PropTypes.string.isRequired,
  settings: PropTypes.object,
};

export default memo(OrgProfileDescription);
