import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import Image from 'components/common/Image';

const useStyles = makeStyles()({
  root: {
    paddingBottom: '29.5% !important',
  },
});

function OrgProfileImage({ src, alt, className, objectFit = 'cover' }) {
  const { classes, cx } = useStyles();
  return <Image className={cx(classes.root, className)} src={src} alt={alt} objectFit={objectFit} />;
}

OrgProfileImage.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  className: PropTypes.string,
  objectFit: PropTypes.string,
};

export default memo(OrgProfileImage);
