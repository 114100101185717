import React, { memo } from 'react';
import RoomIcon from '@mui/icons-material/Room';
import HomeIcon from '@mui/icons-material/Home';
import EmailIcon from '@mui/icons-material/Email';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import { makeStyles } from 'tss-react/mui';
import { Grid, IconButton, Link, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { useTranslation } from 'i18n';
import Image from 'components/common/Image';

import { openInGoogleMaps, openMail } from 'utils/links';
import Button from '../common/Button';
import Logo from '../common/Logo';

const useStyles = makeStyles()((theme) => ({
  sidebarContainer: {
    [theme.breakpoints.up('md')]: {
      maxWidth: '288px',
    },
  },
  contentContainer: {
    marginTop: theme.spacing(3),
  },
  subtitle: {
    fontWeight: 500,
  },
  parentLogo: {
    height: '80px',
  },
  linkLowercase: {
    textTransform: 'lowercase',
    fontWeight: 'normal',
  },
  linkCapitalize: {
    textTransform: 'capitalize',
    fontWeight: 'normal',
  },
  listItemPadding: {
    padding: theme.spacing(0.5, 0),
  },
  jobsContainer: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 172,
    },
  },
  textListItem: {
    paddingTop: 2,
    textTransform: 'uppercase',
    color: theme.palette.text.primary,
    fontWeight: 500,
    fontSize: 13,
    lineHeight: '120%',
    letterSpacing: '0.03em',
  },
  iconListItem: {
    minWidth: 'unset',
    paddingRight: 9,
    color: theme.palette.primary.main,
  },
}));

interface OrgSidebarProps {
  parent?: any;
  title: string;
  siteUrl?: string;
  siteUrlPreview?: string;
  address?: any;
  facebook?: string;
  twitter?: string;
  instagram?: string;
  email?: string;
  settings?: any;
}

const OrgSidebar: React.FC<React.PropsWithChildren<OrgSidebarProps>> = ({
  title,
  address,
  parent,
  siteUrl,
  siteUrlPreview,
  facebook,
  twitter,
  instagram,
  email,
  settings,
}) => {
  const { classes } = useStyles();
  const [t] = useTranslation('public');

  const { address_name, city, state_display, postal_code, country, address1 } = address;

  const haveSocialNetwork = twitter || instagram || facebook;

  return (
    <Grid container direction="column" className={classes.sidebarContainer}>
      <Grid item xs={12} container spacing={1}>
        <Grid item>
          <Typography variant="h5">{title}</Typography>
        </Grid>
        {parent && (
          <Grid item container direction="column" justifyContent="center" spacing={1}>
            <Grid item>
              <Typography variant="body2" color="textSecondary" className={classes.subtitle}>
                {t<string>('common:statement.poweredBy')}
              </Typography>
            </Grid>

            <Grid item>
              <Logo xs={80} sm={80} md={80}>
                {parent.logo && <Image src={parent.logo} className={classes.parentLogo} alt={parent.title} />}
              </Logo>
            </Grid>
          </Grid>
        )}
      </Grid>

      <Grid item container className={classes.contentContainer}>
        <Grid item xs={12}>
          <List dense disablePadding>
            <ListItem disableGutters alignItems="flex-start" className={classes.listItemPadding}>
              <ListItemIcon className={classes.iconListItem}>
                <RoomIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText className={classes.textListItem}>
                <Typography variant="body2">
                  {!address_name ? t('common:statement.address') : address_name}
                  <br />
                </Typography>
                <Link
                  className={classes.linkCapitalize}
                  href={openInGoogleMaps(country, city, address_name)}
                  target="_blank"
                  align="left"
                >
                  <Typography variant="body2">
                    {address1 && `${address1}, `}
                    {city} {state_display && state_display} {postal_code && postal_code}
                  </Typography>
                </Link>
              </ListItemText>
            </ListItem>

            {siteUrl && (
              <ListItem disableGutters alignItems="flex-start" className={classes.listItemPadding}>
                <ListItemIcon className={classes.iconListItem}>
                  <HomeIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText className={classes.textListItem}>
                  <Typography style={{ fontWeight: 500 }} variant="body2">
                    {t<string>('common:statement.officialPage')} <br />
                  </Typography>
                  <Link className={classes.linkLowercase} href={siteUrl} target="_blank" align="left">
                    <Typography variant="body2">{!siteUrlPreview ? siteUrl : siteUrlPreview}</Typography>
                  </Link>
                </ListItemText>
              </ListItem>
            )}

            <ListItem disableGutters alignItems="flex-start" className={classes.listItemPadding}>
              {email && (
                <ListItemIcon className={classes.iconListItem}>
                  <EmailIcon fontSize="small" />
                </ListItemIcon>
              )}
              <ListItemText
                className={classes.textListItem}
                secondary={
                  haveSocialNetwork && (
                    <Grid item container spacing={1}>
                      {facebook && (
                        <Grid item>
                          <IconButton size="small" edge="start">
                            <Link href={facebook} target="_blank">
                              <FacebookIcon color="primary" />
                            </Link>
                          </IconButton>
                        </Grid>
                      )}
                      {instagram && (
                        <Grid item>
                          <IconButton size="small" edge="start">
                            <Link href={instagram} target="_blank">
                              <InstagramIcon color="primary" />
                            </Link>
                          </IconButton>
                        </Grid>
                      )}
                      {twitter && (
                        <Grid item>
                          <IconButton size="small" edge="start">
                            <Link href={twitter} target="_blank">
                              <TwitterIcon color="primary" />
                            </Link>
                          </IconButton>
                        </Grid>
                      )}
                    </Grid>
                  )
                }
              >
                {email && (
                  <>
                    {t<string>('common:statement.contactUs')} <br />
                    <Link className={classes.linkLowercase} href={openMail(email)} align="left">
                      {email}
                    </Link>
                  </>
                )}
              </ListItemText>
            </ListItem>
          </List>
        </Grid>
        {settings?.jobs_enabled && settings?.jobs_slug && (
          <Grid item className={classes.jobsContainer}>
            <Button
              fullWidth
              rounded
              variant="contained"
              color="primary"
              size="medium"
              href={`https://jobs.jlive.app/companies/${settings.jobs_slug}`}
              target="_blank"
            >
              {t<string>('common:general.jobs').toUpperCase()}
            </Button>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default memo(OrgSidebar);
